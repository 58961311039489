import React, { Component } from 'react';
import Icon from '../shared/Icon';

import ContextMenu from '../shared/ContextMenu';

class FileContextMenu extends Component {
  handleDelete = (e) => {
    e.preventDefault();

    if (confirm(`Bist du sicher, dass du die Dokumente löschen willst?`)) {
      this.props.onDelete();
    }
  };

  renderItems() {
    const items = [];

    if (this.props.selectedAttachments.length === 0) return null;

    if (this.props.selectedAttachments.length === 1) {
      items.push(
        <a key="open" className="dropdown-item" href={this.props.selectedAttachments[0].file_url} target="_blank"><Icon name="eye" fixedWidth /> Öffnen</a>
      );
    }

    items.push(
      <button key="download" className="dropdown-item" onClick={this.props.onDownload}><Icon name="download" fixedWidth /> Download</button>
    );

    items.push(
      <button key="datapoint" className="dropdown-item" onClick={this.props.handleAssignDataPointClick}><Icon name="database" fixedWidth /> Datenpunkt zuweisen</button>
    );

    if (this.props.selectedAttachments.length === 1) {
      items.push(
        <button key="edit" className="dropdown-item" onClick={this.props.handleRenameClick}><Icon name="pencil" fixedWidth /> Umbenennen</button>
      );

      items.push(
        <button key="copy" className="dropdown-item" onClick={this.props.handleCopyClick}><Icon name="copy" fixedWidth /> Kopie erstellen</button>
      );
    }

    items.push(
      <hr key="seperator" role="separator" className="dropdown-divider" />
    );

    items.push(
      <a key="trash" className="dropdown-item" onClick={this.handleDelete} href="#"><Icon name="trash" fixedWidth /> Löschen</a>
    );

    return items;
  }


  render() {
    return (
      <ContextMenu {...this.props}>
        {this.renderItems()}
      </ContextMenu>
    );
  }
}

export default FileContextMenu;
