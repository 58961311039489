/* global window, URLSearchParams */
import { Turbo } from '@hotwired/turbo-rails';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TagSelectorAsync from './TagSelectorAsync';

import Icon from '../shared/Icon';

class TagFilter extends Component {
  handleTagSelected = (tag) => {
    const newId = tag.id;
    const urlParams = new URLSearchParams(window.location.search);
    const tagsString = urlParams.get(this.props.param) || '';
    const tags = (tagsString === '') ? [] : tagsString.split('.');

    if (!tags.includes(newId)) {
      tags.push(newId);
    }

    urlParams.set(this.props.param, tags.join('.'));

    const path = `${window.location.pathname}?${urlParams.toString()}`;

    Turbo.visit(path);
  };

  // eslint-disable-next-line class-methods-use-this
  renderButton = (ref, instance) => {
    return (
      <button ref={ref} className="form-control-sm btn btn-sm btn-light dropdown-toggle" onClick={instance.toggleDropDown}>
        <Icon name="tags" /> <span className="caret" />
      </button>
    );
  };

  render() {
    return (
      <TagSelectorAsync
        placeholder={this.props.placeholder}
        target={this.renderButton}
        onAdd={this.handleTagSelected}
        optionsUrl={this.props.optionsUrl} />
    );
  }
}

TagFilter.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  param: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

TagFilter.defaultProps = {
  param: 't',
};

export default TagFilter;
