/* eslint-disable quote-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProjectTypeFilterSelector from './ProjectTypeFilterSelector';

import { getAsync } from '../../helpers/rails_helper';

class ProjectTypeFilterSelectorAsync extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  handleOpen = () => {
    getAsync(this.props.optionsUrl).then((options) => {
      return options.project_types;
    }).then((options) => {
      this.setState({ options });
    });
  };

  handleClose = () => {
    this.setState({
      options: [],
    });
  };

  render() {
    return (
      <ProjectTypeFilterSelector
        {...this.props}
        options={this.state.options}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      />
    );
  }
}

ProjectTypeFilterSelectorAsync.propTypes = {
  onAdd: PropTypes.func,
  optionsUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  target: PropTypes.func,
  placeholder: PropTypes.string,
};

ProjectTypeFilterSelectorAsync.defaultProps = {
  buttonText: 'Typ',
};

export default ProjectTypeFilterSelectorAsync;
