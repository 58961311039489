// Entry point for the build script in your package.json

if (process.env.NODE_ENV === 'development') {
  console.log('AUTO RELOAD ENABLED IN DEVELOPMENT');
  const source = new EventSource('http://localhost:3001');
  source.onmessage = (event) => {
    if (event.data === 'reload') {
      window.location.reload();
    }
  };
}

import '../application.js';
