/* global document */

// TODO: Move this global variable to a Stimulus controller
const toggleTrashed = (id) => {
  const el = document.getElementById(id);

  if (el.style.display === 'none') {
    el.style.display = 'block';
  } else {
    el.style.display = 'none';
  }
};

window.toggleTrashed = toggleTrashed;
