/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Draggable } from 'react-beautiful-dnd';

import File from './File';
import Icon from '../shared/Icon';

import {
  metaOrCtrlKey,
  convertInlineStyleToCssVariables,
} from '../../helpers/helpers';

// calculate position of ghost handle while dragging
// limit the postion to inside the container element
const calcNewHandlePosition = (mouseEvent, containerElement) => {
  const rectangleHeight = 24;
  const rectangleWidth = 24;

  const box = containerElement.getBoundingClientRect();

  const position = {
    x: mouseEvent.clientX - box.left - (rectangleHeight / 2),
    y: mouseEvent.pageY - window.scrollY - box.top - (rectangleHeight / 2),
  };

  const minX = 0;
  const maxX = box.width - rectangleWidth;

  const minY = 0;
  const maxY = box.height - rectangleHeight;

  if (position.x < minX) {
    position.x = minX;
  } else if (position.x > maxX) {
    position.x = maxX;
  }

  if (position.y < minY) {
    position.y = minY;
  } else if (position.y > maxY) {
    position.y = maxY;
  }

  return position;
};

// generate the style for the element being dragged or dropped
const getStyle = (style, snapshot, handlePosition) => {
  // we use css variables instead of inline styles
  let cssVariables = convertInlineStyleToCssVariables(style);

  if (snapshot.isDragging) {
    if (cssVariables['--top'] !== undefined) {
      cssVariables['--top'] = cssVariables['--top'] + 'px';
      cssVariables['--left'] = cssVariables['--left'] + 'px';
      cssVariables['cursor'] = 'grab';
    }
  } else {
    cssVariables['--top'] = handlePosition.y + 'px';
    cssVariables['--left'] = handlePosition.x + 'px';
    cssVariables['cursor'] = 'default';
  }

  if (!snapshot.isDropAnimating) {
    return cssVariables;
  }

  const { moveTo, curve } = snapshot.dropAnimation;

  let duration = '0.75';
  let translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  let scale = 'scale(0.5)';

  // slightly different animation, when we have a cancel drop
  // (not dropping on a target)
  if (!snapshot.draggingOver) {
    translate = `translate(0px, 0px)`;
    scale = '';
    duration = '0.3';
  }
  
  return {
    ...style,
    transform: `${translate} ${scale}`,
    opacity: 0,
    transition: `all ${curve} ${duration}s`,
  };
};


class FileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      draggingDisabled: false,
      handlePosition: { x: 0, y: 0 },
    };

    this.fileContainerRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('touchend', this.handleMouseUp);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      if (this.state.dragging) {
        this.setState({
          dragging: false,
        });
      }
      this.props.selectRangeOfAttachments('none');
    }
    if (metaOrCtrlKey(event) && event.key === 'a') {
      this.props.selectRangeOfAttachments('all');
      event.preventDefault();
    }
  };

  handleMouseClick = (event) => {
    event.preventDefault();

    if (event.button !== 0 && !this.props.isSelected) {
      // right click
      this.props.selectRangeOfAttachments('only', this.props.attachment);
    } else if (metaOrCtrlKey(event)) {
      this.props.selectRangeOfAttachments('toggle', this.props.attachment);
    } else if (event.shiftKey) {
      this.props.selectRangeOfAttachments('between', this.props.attachment);
    } else if (event.button === 0) {
      this.props.selectRangeOfAttachments('only', this.props.attachment);
    }

    this.setState({
      dragging: false,
      draggingDisabled: false
    });
  };

  handleTouchStart = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();

    this.setState({
      elementStartRect: rect,
      mouseStartX: e.touches[0].clientX,
      mouseStartY: e.touches[0].clientY,
      pageXOffset: 0,
      pageYOffset: 0,
    });
  };

  handleMouseUp = () => {
    this.setState({
      dragging: false,
      draggingDisabled: false,
    });
  };

  handleCopyClick = (e) => {
    e.preventDefault();

    this.props.onCopy(this.props.attachment);
  };

  isDragDisabled = () => {
    return this.state.renameModalVisible || this.state.draggingDisabled || !this.props.editable;
  };

  handleMouseMove = (e) => {
    if (this.props.isDragging) return;

    const newHandlePosition = calcNewHandlePosition(e, this.fileContainerRef.current);

    this.setState(() => ({
      handlePosition: newHandlePosition,
    }));
  };

  renderDraggable(provided, snapshot) {
    return (
      <div
        ref={this.fileContainerRef}
        onMouseMove={this.handleMouseMove}
        onDoubleClick={this.props.onDoubleClick}
        className={['draggable-file', this.props.isSelected || this.state.dragging || snapshot.isDragging ? 'selected' : null, this.state.dragging || snapshot.isDragging ? 'dragging' : null].filter(v => !!v).join(' ')}
        onClick={this.handleMouseClick}
        onContextMenu={this.handleMouseClick}>
          <div
            id={`${this.props.attachment.id}-File`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided.draggableProps.style, snapshot, this.state.handlePosition)}
            className={['dragging-info-box', this.state.dragging || snapshot.isDragging ? 'dragging' : ''].join(' ').trim()}
          >
            <div className="visible-summary" data-selected-count={this.props.selectedAttachments.length}>
              <div className="visible-summary-text">
                {this.props.selectedAttachments.map(f => f.document.name).join(', ')}
              </div>
            </div>
          </div>
          <File
            {...this.props.attachment}
            ref={this.buttonRef}
            key={this.props.attachment.id}
            previewable
          />
      </div>
    );
  }

  render() {
    return (
      <Draggable
        draggableId={`${this.props.attachment.id}-File`}
        isDragDisabled={this.isDragDisabled()}
        index={this.props.index}>
        {(provided, snapshot) => {
          return this.renderDraggable(provided, snapshot);
        }}
      </Draggable>
    );
  }
}

FileContainer.propTypes = {
  editable: PropTypes.bool.isRequired,
  attachment: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  selectedAttachments: PropTypes.array.isRequired,
  selectRangeOfAttachments: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default FileContainer;
