import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';

import FileContainer from './FileContainer';
import Icon from '../shared/Icon';

import AttachmentPreviewer from '../Attachments/AttachmentPreviewer';

import ContextMenuContainer from '../shared/ContextMenuContainer';
import FileContextMenu from './FileContextMenu';

import RenameObjectModal from './RenameObjectModal';
import AssignToDataPointModal from './AssignToDataPointModal';

const sortAttachments = (files, field = 'updated_at', direction = -1) => {
  return files.sort((a, b) => {
    if (direction === -1) {
      return b[field] - a[field];
    }
    return a[field] - b[field];
  });
};

class FileArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renameModalVisible: false,
      assignDataPointModalVisible: false,
    };
  }

  handleRenameClick = (e) => {
    e.preventDefault();
    this.setState({
      renameModalVisible: true,
      selectedAttachment: this.props.selectedAttachments[0],
    });
  };

  handleCopyClick = (e) => {
    e.preventDefault();

    if (this.props.selectedAttachments.length === 1) {
      this.props.onCopy(this.props.selectedAttachments[0]);
    }
  };

  handleAssignDataPointClick = (e) => {
    e.preventDefault();

    this.setState({
      assignDataPointModalVisible: true,
      selectedAttachments: this.props.selectedAttachments,
    });
  };

  handleModalClosed = () => {
    this.setState({
      renameModalVisible: false,
      assignDataPointModalVisible: false,
      selectedAttachment: null,
    });

    this.props.selectRangeOfAttachments('none');
  };

  handleContextMenu = (event) => {
    this.props.selectRangeOfAttachments('only', this.props.file);
    event.preventDefault();
  };

  renderHead() {
    if (this.props.layout === 'grid') return null;

    return (
      <div className="head">
        <div className="name">Name</div>
        <div className="owner d-none d-sm-block">Eigentümer</div>
        <div className="date d-none d-sm-block">Hochgeladen am</div>
      </div>
    );
  }

  renderFiles(openPreview) {
    if (this.props.attachments.length > 0) {
      const sortedAttachments = sortAttachments(this.props.attachments);

      return sortedAttachments.map((attachment, index) => (
        <FileContainer
          onDoubleClick={_ => openPreview(attachment)}
          attachment={attachment}
          index={index}
          editable={this.props.editable}
          selectedAttachments={this.props.selectedAttachments}
          selectRangeOfAttachments={this.props.selectRangeOfAttachments}
          isSelected={this.props.selectedAttachments
            && this.props.selectedAttachments.filter(f => attachment.id === f.id).length > 0}
          key={`File-${attachment.id}`}
          isDragging={this.props.isDragging}
        />
      ));
    }

    return null;
  }

  renderNoFiles() {
    return (
      <div className="no-files-in-folder">
        <Icon name="cloud-upload" className="no-file-icon"/>
        <p className="head">Keine Dateien in diesem Ordner</p>
        {this.props.editable ? (
          <p className="d-none d-sm-inline">Ziehe Dateien in diese Feld, um sie hochzuladen.</p>
        ) : null}
      </div>
    );
  };

  renderLoading() {
    return (
      <div className="files-loading">
        <Icon name="circle-notch" spin className="files-loading-icon"/>
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="file-area">
          {this.renderLoading()}
        </div>
      );
    }

    if (this.props.attachments && !this.props.attachments.length) {
      return (
        <div className="file-area">
          {this.renderNoFiles()}
        </div>
      );
    }

    if (this.props.attachments && this.props.attachments.length) {
      return (
        <div className="file-area">
          {this.renderHead()}
          <ContextMenuContainer enabled={this.props.editable}>
            {(menu) => (
              <Fragment>
                <FileContextMenu
                  menu={menu}
                  selectedAttachments={this.props.selectedAttachments}
                  onDelete={this.props.onDelete}
                  handleRenameClick={this.handleRenameClick}
                  handleCopyClick={this.handleCopyClick}
                  handleAssignDataPointClick={this.handleAssignDataPointClick}
                  onDownload={this.props.onDownload}
                />
                <AttachmentPreviewer attachments={this.props.attachments}>
                  {(openPreview) => (
                    <Droppable droppableId="file-area" isDropDisabled>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} className={`file-area-${this.props.layout}`}>
                          {this.renderFiles(openPreview)}
                        </div>
                      )}
                    </Droppable>
                  )}
                </AttachmentPreviewer>
              </Fragment>
            )}
          </ContextMenuContainer>

          {(this.state.selectedAttachment?.id) && (
            <>
              <RenameObjectModal
                title="Dokument umbenennen"
                defaultValue={this.state.selectedAttachment.document?.name}
                objectId={this.state.selectedAttachment.id}
                onClose={this.handleModalClosed}
                renameObject={this.props.onRename}
                visible={this.state.renameModalVisible}
              />
            </>
          )}

          {(this.state.selectedAttachments) && (
            <>
              <AssignToDataPointModal
                title="Datenpunkt zuweisen"
                projectId={this.state.selectedAttachments[0].attachable_id}
                visible={this.state.assignDataPointModalVisible}
                selectedAttachments={this.state.selectedAttachments}
                onClose={this.handleModalClosed}
                onDataPointAssignment={this.props.onDataPointAssignment}
              />
            </>
          )}
        </div>
      );
    }

    return null;
  }
}

FileArea.propTypes = {
  attachments: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onDataPointAssignment: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  selectedAttachments: PropTypes.array.isRequired,
  selectRangeOfAttachments: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

export default FileArea;
