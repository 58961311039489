import c0 from './allocate_order_requests_controller';
import c1 from './appointment_controller';
import c2 from './assignment_price_controller';
import c3 from './body_controller';
import c4 from './clipboard_controller';
import c5 from './confirm_order_requests_controller';
import c6 from './craft_appointment_types_controller';
import c7 from './data_point_template_controller';
import c8 from './dropdown_toggle_controller';
import c9 from './email_template_controller';
import c10 from './flash_controller';
import c11 from './form_submit_controller';
import c12 from './installation_appointment_accepting_controller';
import c13 from './installation_appointment_cancellation_controller';
import c14 from './installation_appointment_controller';
import c15 from './installation_appointment_declining_controller';
import c16 from './installation_capacity_controller';
import c17 from './installation_controller';
import c18 from './installation_installation_company_controller';
import c19 from './installations_controller';
import c20 from './postal_address_controller';
import c21 from './project_order_requests_controller';
import c22 from './project_states_list_controller';
import c23 from './projects_sidebar_controller';
import c24 from './retrieve_order_requests_controller';
import c25 from './sort_controller';
import c26 from './tags_list_controller';
import c27 from './toggle_edit_controller';
import c28 from './turbo_modal_controller';
export const definitions = [
	{identifier: 'allocate-order-requests', controllerConstructor: c0},
	{identifier: 'appointment', controllerConstructor: c1},
	{identifier: 'assignment-price', controllerConstructor: c2},
	{identifier: 'body', controllerConstructor: c3},
	{identifier: 'clipboard', controllerConstructor: c4},
	{identifier: 'confirm-order-requests', controllerConstructor: c5},
	{identifier: 'craft-appointment-types', controllerConstructor: c6},
	{identifier: 'data-point-template', controllerConstructor: c7},
	{identifier: 'dropdown-toggle', controllerConstructor: c8},
	{identifier: 'email-template', controllerConstructor: c9},
	{identifier: 'flash', controllerConstructor: c10},
	{identifier: 'form-submit', controllerConstructor: c11},
	{identifier: 'installation-appointment-accepting', controllerConstructor: c12},
	{identifier: 'installation-appointment-cancellation', controllerConstructor: c13},
	{identifier: 'installation-appointment', controllerConstructor: c14},
	{identifier: 'installation-appointment-declining', controllerConstructor: c15},
	{identifier: 'installation-capacity', controllerConstructor: c16},
	{identifier: 'installation', controllerConstructor: c17},
	{identifier: 'installation-installation-company', controllerConstructor: c18},
	{identifier: 'installations', controllerConstructor: c19},
	{identifier: 'postal-address', controllerConstructor: c20},
	{identifier: 'project-order-requests', controllerConstructor: c21},
	{identifier: 'project-states-list', controllerConstructor: c22},
	{identifier: 'projects-sidebar', controllerConstructor: c23},
	{identifier: 'retrieve-order-requests', controllerConstructor: c24},
	{identifier: 'sort', controllerConstructor: c25},
	{identifier: 'tags-list', controllerConstructor: c26},
	{identifier: 'toggle-edit', controllerConstructor: c27},
	{identifier: 'turbo-modal', controllerConstructor: c28},
];
