/* global document, CustomEvent */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Icon from '../shared/Icon';
import Button from '../shared/Button';

import { subscribe, unsubscribe } from '../../helpers/actioncable_helper';

import { updateAsync } from '../../helpers/rails_helper';

import QuickDelaySelector from '../shared/QuickDelaySelector';

import TextArea from '../shared/TextArea';
import DatetimePicker from "../DatePickers/DatetimePickerComponent";

class TodoDue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todo: props.todo,
      reason: '',
      changedTodo: props.todo,
      editing: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.channel = subscribe({
      channel: 'TodoChangesChannel',
      todo_id: this.state.todo.id,
    }, ({ action, todo }) => {
      if (action === 'update') {
        this.setState({ todo, editing: false, saving: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
    }
  }

  handleQuickDelaySelected = (value) => {
    this.setState({
      changedTodo: Object.assign({}, this.state.changedTodo, {
        due: (value && value.date),
      }),
    });
  }

  handleChange = (newValue) => {
    this.setState({
      changedTodo: Object.assign({}, this.state.changedTodo, {
        due: (newValue && newValue),
      }),
    });
  }

  handleReasonChange = (e) => {
    this.setState({
      reason: e.target.value,
    });
  }

  handleSaveClick = (e) => {
    e.preventDefault();

    this.setState({ saving: true });

    updateAsync(`/todos/${this.state.todo.id}/delay.json`, {
      todo: {
        due: this.state.changedTodo.due || '',
      },
      delay_reason: this.state.reason,
    }, 'PATCH').then((result) => {
      const event = new CustomEvent('todo:activities:new', {
        detail: {
          todoId: this.state.todo.id,
          activities: result.new_activities || [],
        },
      });

      document.dispatchEvent(event);

      this.setState({
        editing: false,
        saving: false,
        todo: result.todo,
        reason: '',
      });
    }).catch((errorResponse) => {
      this.setState({ saving: false }, () => {
        alert(errorResponse.errors.join('\n'));
      });
    });
  }

  toggleEdit = (e) => {
    e.preventDefault();
    this.setState({
      editing: !this.state.editing,
      changedTodo: this.state.todo,
    });
  }

  renderEditButton() {
    if (this.props.mayDelay) {
      return (
        <a href="" className="todo-toggle-responsible-link" onClick={this.toggleEdit}>
          <Icon name="cog" />
        </a>
      );
    }

    return null;
  }

  renderDue() {
    if (this.state.todo.due) {
      return moment(this.state.todo.due).format('dddd DD. MMMM YYYY, HH:mm');
    }

    return 'Keine Fälligkeit eingetragen';
  }

  renderReasonInput() {
    if (!this.state.todo.delay_reason_required) return null;

    return (
      <div style={{ marginBottom: 10 }}>
        <TextArea
          value={this.state.reason}
          onChange={this.handleReasonChange}
          className="form-control"
          autoSize
          disabled={this.state.saving}
          placeholder="Bitte Grund eingeben"
        />
      </div>
    );
  }

  renderForm() {
    return (
      <Fragment>
        <div style={{ marginBottom: 10 }}>
          <DatetimePicker
            size="small"
            isDisabled={this.state.saving}
            value={this.state.changedTodo.due}
            onChange={this.handleChange}
          />
          <QuickDelaySelector onChange={this.handleQuickDelaySelected} />
        </div>
        {this.renderReasonInput()}
        <p>
          <Button loading={this.state.saving} className="btn btn-sm btn-primary" onClick={this.handleSaveClick}>
            Speichern
          </Button>{' '}
          <button className="btn btn-sm btn-link" onClick={this.toggleEdit}>
            abbrechen
          </button>
        </p>
        <hr />
      </Fragment>
    );
  }

  render() {
    if (this.state.todo.complete) {
      return null;
    }

    return (
      <div>
        <div className="todo-side-headline">Fälligkeit
          {this.renderEditButton()}
        </div>
        <div>
          {this.state.editing ? (
            this.renderForm()
          ) : (
            <p className="text-muted">
              {this.renderDue()}
            </p>
          )}
        </div>
        <hr />
      </div>
    );
  }
}

TodoDue.propTypes = {
  todo: PropTypes.object.isRequired,
  mayDelay: PropTypes.bool,
};

export default TodoDue;

//   <% if todo.active && todo.due.present? %>
//     <p id="todo-due"><%= todo.due.present? ? l(todo.due.to_date, format: :long) : "Kein Datum eingetragen" %></p>
//   <% elsif todo.days_to_due.present? %>
//     <p id="todo-due"><%= pluralize(todo.days_to_due, "Tag", "Tage") %> nach Aktivierung</p>
//   <% else %>
//     <p id="todo-due">Keine Fälligkeit eingetragen</p>
//   <% end %>



