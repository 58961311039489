// https://www.cjav.dev/articles/how-to-setup-react-rails-with-esbuild
import * as Components from "../components/**/*.{js,ts,tsx,jsx}";

const componentsContext = {}

for (const [i, filename] of Object.entries(Components.filenames)) {
  const name = filename.replace(/^\.+\/components\//, '').replace(/\.(js|ts|tsx|jsx)$/, '');
  componentsContext[name] = Components.default[i].default;
}

const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.getConstructor = (name) => componentsContext[name]

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount, false);
