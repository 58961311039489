import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '../shared/Modal';
import AttachmentPreviewer from '../Attachments/AttachmentPreviewer';
import ComposeAttachment from '../Todos/ComposeAttachment';
import csrf_token from '../../initializers/csrf_token';

class AssignToDataPointModal extends Component {

  static fetchHeader() {
    return {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token(),
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      value: '',
      dataPoints: [],
    };
  }

  componentDidMount() {
    this.loadAndSetDataPointsAndTemplate();
  }

  componentDidUpdate(prevProps) {
    // only update if selectedAttachments have changed
    if (
      JSON.stringify(prevProps.selectedAttachments) !==
      JSON.stringify(this.props.selectedAttachments)
    ) {
      this.loadAndSetDataPointsAndTemplate();
    }
  }

  currentValueFromDataPoints = (dataPoints) => {
    let value = '';
    if (this.props.selectedAttachments.length === 1) {
      value = dataPoints
        .filter(
          dataPoint => dataPoint.value.map(
            v => v.document_id,
          )
            .includes(this.props.selectedAttachments[0].document_id),
        )[0]?.data_point_template_id || '';
    } else {
      const values = dataPoints
        .filter(
          dataPoint => {
            return this.props.selectedAttachments.map(v => v.document_id)
              .includes(dataPoint.value[0]?.document_id);
          },
        );
      if (values.length === 1) {
        value = values[0].data_point_template_id;
      }
    }
    return value;
  };

  async loadAndSetDataPointsAndTemplate() {
    const response = await fetch(`/projects/${this.props.projectId}/data_points.json?type=DataPoint::DocumentDataPoint`);
    const dataPoints = (await response.json())
      .data_schemas
      .filter(d => d.data_points.length > 0)
      .map(d => d.data_points)
      .flat();
    this.setState({
      dataPoints,
      value: this.currentValueFromDataPoints(dataPoints),
    });
  }

  isValid = () => {
    return !!this.state.value;
  };

  deleteDocumentAssignments = async (documentIDs) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const document_id of documentIDs) {
      await fetch(`/documents/${document_id}/delete_data_points_assignments`, {
        method: 'POST',
        headers: AssignToDataPointModal.fetchHeader(),
        body: JSON.stringify({
          project_id: this.props.projectId,
        }),
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value.trim(),
    });
  };

  handleChangeTemplate = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSaveObjectClick = (e) => {
    e.preventDefault();

    const document_ids = this.props.selectedAttachments.map(v => v.document_id);

    if (!this.state.value) {
      return this.deleteDocumentAssignments(document_ids)
        .then(async () => {
          this.props.onDataPointAssignment();
          this.props.onClose();
        });
    }

    fetch(`/data_points/${this.state.value}/assign_attachment?project_id=${this.props.projectId}`, {
      method: 'POST',
      headers: AssignToDataPointModal.fetchHeader(),
      body: JSON.stringify({
        project_id: this.props.projectId,
        data_point_template_id: this.state.value,
        document_ids,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          this.props.onDataPointAssignment();
          this.props.onClose();
        }
      });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleSaveObjectClick(e);
    }
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.props.onClose();

    this.setState({ value: '' });
  };

  handleClick = (e) => e.stopPropagation();

  renderContent() {
    return (
      <div onClick={this.handleClick}>
        <div className="react-modal-header">
          <h4>
            {this.props.title}
          </h4>
        </div>
        <div className="react-modal-content">
          <select className="form-select"
                  aria-label="Bitte Datenpunkt auswählen"
                  onChange={this.handleChangeTemplate}
                  value={this.state.value}>
            <option value="">(Keine Zuweisung)</option>
            {this.state.dataPoints.map((dataPoint) => (
              <option
                key={dataPoint.data_point_template_id}
                value={dataPoint.data_point_template_id}
              >{dataPoint.title}</option>
            ))}
          </select>
        </div>
        <div className="react-modal-footer">
          <div>
            <button
              disabled={this.state.saving}
              type="button"
              className="btn btn-link me-3"
              onClick={this.handleCancelClick}>
              Abbrechen
            </button>
            <button
              type="button"
              className={`btn btn-primary ${this.state.saving ? 'btn-loading' : ''}`}
              onClick={this.handleSaveObjectClick}>
              Speichern
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal visible={this.props.visible} onClose={this.props.onClose}>
        {this.renderContent()}
      </Modal>
    );
  }
}

AssignToDataPointModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  projectId: PropTypes.number.isRequired,
  selectedAttachments: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onDataPointAssignment: PropTypes.func.isRequired,
};

export default AssignToDataPointModal;
