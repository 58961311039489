/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import {
  getAsync,
  postAsync,
  deleteAsync,
  updateAsync,
} from '../../helpers/rails_helper';

import UploadButton from './UploadButton';
import NewFolderButton from './NewFolderButton';
import SwitchLayoutButton from './SwitchLayoutButton';

import ShareNotice from './ShareNotice';
import FolderArea from './FolderArea';
import FileArea from './FileArea';
import FolderBreadcrumbs from './FolderBreadcrumbs';

import { flashNotice, flashError } from '../../helpers/flash_helper';

const { fromEvent } = require('file-selector');

class FileBrowser extends Component {
  createFolder = (name) => {
    return postAsync('/folders', {
      folder: {
        name,
        folderable_typed_id: this.props.selectedFolderableTypedId,
      },
    }).then((newFolder) => {
      this.props.onFolderAdded(newFolder);
    }).catch(response => {
      console.log(response.error);
      alert(response.error);
    });
  };

  deleteFolder = (folder) => {
    return deleteAsync(`/folders/${folder.id}`).then(() => {
      this.props.onFolderDeleted(folder.typed_id);
    });
  };

  deleteselectedAttachments = () => {
    const ids = this.props.selectedAttachments.map(f => f.id);

    return deleteAsync(`/attachments/delete_multiple`, { ids }).then(() => {
      this.props.onFilesDeleted(ids);
    });
  };

  downloadselectedAttachments = () => {
    // Download single file directly.
    // Request multiple files as zip archive.
    if (this.props.selectedAttachments.length === 1) {
      window.location = this.props.selectedAttachments[0].document.download_url;
    } else if (this.props.selectedAttachments.length > 1) {
      const ids = this.props.selectedAttachments.map(f => f.id);
      const query = ids.map((id) => `ids[]=${id}`).join('&');
      const url = `/attachments/download_multiple?${query}`;

      flashNotice('Download wird vorbereitet...');

      fetch(url).then((response) => {
        return response.status === 200 ? response.blob() : Promise.reject('something went wrong');
      }).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'wegateam-download.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        flashNotice('Download abgeschlossen.');
      }).catch(() => {
        flashError('Download fehlgeschlagen.');
      });
    }
  };

  copyFile = (file) => {
    return postAsync(`/attachments/${file.id}/copy`).then((newAttachment) => {
      this.props.onFileAdded(newAttachment);
    });
  };

  renameFolder = (id, name) => {
    return updateAsync(`/folders/${id}`, {
      folder: {
        name,
      },
    }).then((result) => {
      this.props.onFolderUpdated(result);
    }).catch((error) => {
      flashError(error.errors.join(','));
    });
  };

  renameFile = (id, name) => {
    return updateAsync(`/attachments/${id}`, {
      attachment: {
        name,
      },
    }).then((updatedFile) => {
      this.props.onFileUpdated(updatedFile);
    });
  };

  handleDropFiles = (acceptedFiles, rejectedFiles, event) => {
    this.context.uploadFiles(acceptedFiles, {
      attachable_typed_id: this.props.selectedFolderableTypedId,
    });

    event.stopPropagation();
    event.preventDefault();
  };

  renderNewFolderButton = () => {
    const selecetedFoderable = this.props.getFolderable(this.props.selectedFolderableTypedId);

    if (selecetedFoderable && selecetedFoderable.allow_manual_folders) {
      return <NewFolderButton createFolder={this.createFolder} visible={this.props.editable} />;
    }
    return null;
  };

  renderMultiSelectHint() {
    if (!this.props.attachments || this.props.attachments.length < 2) return null;

    return (
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <span className="badge bg-info">TIPP</span>
        <span style={{ color: 'var(--bs-tertiary-color)', fontSize: 13, fontWeight: 400, marginLeft: 6 }}>
          Benutze die CMD/CTRL/SHIFT Tasten um mehrere Dokumente zu markieren und zu verschieben.
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className="filebrowser">
        <div className="filebrowser-head">
          <FolderBreadcrumbs
            getFolderable={this.props.getFolderable}
            selectedFolderableTypedId={this.props.selectedFolderableTypedId}
            onFolderableSelect={this.props.onFolderableSelect}
          />
          <div className="filebrowser-head-buttons">
            {this.renderNewFolderButton()}
            <SwitchLayoutButton
              onClick={this.props.onLayoutSwitch}
              layout={this.props.layout}
            />
            <UploadButton
              onClick={() => this.dropzoneRef.open()}
              visible={this.props.editable}
            />
          </div>
        </div>
        <ShareNotice
          getFolderable={this.props.getFolderable}
          selectedFolderableTypedId={this.props.selectedFolderableTypedId}
        />
        <FolderArea
          folders={this.props.getSubFolderables(this.props.selectedFolderableTypedId)}
          onDelete={this.deleteFolder}
          onRename={this.renameFolder}
          uploadFiles={this.context.uploadFiles}
          onFolderableSelect={this.props.onFolderableSelect}
          editable={this.props.editable}
        />
        <Dropzone
          className="dropzone"
          activeClassName="active"
          disableClick
          ref={(node) => { this.dropzoneRef = node; }}
          multiple
          disabled={!this.props.editable}
          getDataTransferItems={evt => fromEvent(evt)}
          onDrop={this.handleDropFiles}>
          <FileArea
            onDelete={this.deleteselectedAttachments}
            onCopy={this.copyFile}
            onDownload={this.downloadselectedAttachments}
            onRename={this.renameFile}
            onDataPointAssignment={this.props.onDataPointAssignment}
            attachments={this.props.attachments}
            editable={this.props.editable}
            loading={this.props.loadingAttachments}
            selectedAttachments={this.props.selectedAttachments}
            selectRangeOfAttachments={this.props.selectRangeOfAttachments}
            isDragging={this.props.isDragging}
            layout={this.props.layout}
          />
        </Dropzone>
        {this.renderMultiSelectHint()}
      </div>
    );
  }
}

FileBrowser.contextTypes = {
  uploader: PropTypes.object,
  uploadFiles: PropTypes.func,
};

FileBrowser.propTypes = {
  getFolderable: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  loadingAttachments: PropTypes.bool.isRequired,
  onFolderableSelect: PropTypes.func.isRequired,
  selectedFolderableTypedId: PropTypes.string.isRequired,
  getSubFolderables: PropTypes.func.isRequired,
  onFolderAdded: PropTypes.func.isRequired,
  onFolderUpdated: PropTypes.func.isRequired,
  onFolderDeleted: PropTypes.func.isRequired,
  onFileUpdated: PropTypes.func.isRequired,
  onFilesDeleted: PropTypes.func.isRequired,
  onLayoutSwitch: PropTypes.func.isRequired,
  onDataPointAssignment: PropTypes.func.isRequired,
  selectedAttachments: PropTypes.array.isRequired,
  selectRangeOfAttachments: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  layout: PropTypes.string.isRequired,
};

export default FileBrowser;
