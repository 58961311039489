import { Turbo } from '@hotwired/turbo-rails';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProjectTypeFilterSelectorAsync from './ProjectTypeFilterSelectorAsync';

class ProjectTypeFilter extends Component {
  handleProjectTypeSelected = (project_type) => {
    const newId = project_type.id;
    const urlParams = new URLSearchParams(window.location.search);
    const projectTypesString = urlParams.get(this.props.param) || '';
    const projectTypes = (projectTypesString === '') ? [] : projectTypesString.split('.');

    if (!projectTypes.includes(newId)) {
      projectTypes.push(newId);
    }

    urlParams.set(this.props.param, projectTypes.join('.'));

    const path = `${window.location.pathname}?${urlParams.toString()}`;

    Turbo.visit(path);
  };

  // eslint-disable-next-line class-methods-use-this
  renderButton = (ref, instance) => {
    return (
      <button ref={ref} className="form-control-sm btn btn-sm btn-light dropdown-toggle" onClick={instance.toggleDropDown}>
        Typ <span className="caret" />
      </button>
    );
  };

  render() {
    return (
      <ProjectTypeFilterSelectorAsync
        placeholder={this.props.placeholder}
        target={this.renderButton}
        onAdd={this.handleProjectTypeSelected}
        optionsUrl={this.props.optionsUrl} />
    );
  }
}


ProjectTypeFilter.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  param: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

ProjectTypeFilter.defaultProps = {
  param: 'pt',
};

export default ProjectTypeFilter;
