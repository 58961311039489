/* global document */
import autosize from 'autosize';

window.autosize = autosize;

window.initAutosize = () => {
  autosize(document.querySelectorAll('textarea[data-autosize]'));
};

document.addEventListener('turbo:load', window.initAutosize);
document.addEventListener('turbo:render', window.initAutosize);
