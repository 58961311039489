import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../shared/Tooltip';
import Dropdown from '../shared/Dropdown';

class CompanyRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: true });
  };

  handleClose = () => {
    this.setState({ dropdownOpen: false });
  };

  renderTarget = (ref) => {
    const style = {
      borderRadius: 7,
      borderColor: this.props.role.color,
      marginRight: 0,
    };

    const imageStyle = {
      display: 'inline-block',
      borderRadius: 5,
      backgroundColor: 'var(--bs-body-bg)',
      width: 28,
      height: 28,
    };

    let image;

    if (this.props.role.company) {
      image = (
        <img
          alt={'test'}
          src={this.props.role.company.avatar.thumb.url}
          style={imageStyle}
        />
      );
    }

    const linkStyle = {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      // marginLeft: 1,
    };

    const squareStyle = {
      display: 'inline-flex',
      height: 36,
      width: 36,
      borderRadius: 9,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: this.props.role.color,
      marginLeft: 2,
    };

    const squareStyle2 = {
      display: 'inline-flex',
      height: 32,
      width: 32,
      borderRadius: 7,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--bs-body-bg)',
    };

    const link = (
      <a
        href=""
        ref={ref}
        onClick={this.handleClick}
        style={linkStyle}
      >
        {/* <Icon name="plus" /> */}
        {image}
      </a>
    );

    let title = `${this.props.role.name} auswählen`;

    if (this.props.role.company && this.props.role) {
      title = `${this.props.role.name}: ${this.props.role.company.display_name}`;
    }

    return (
      <Tooltip title={title} placement="top">
        <span>
          <span style={squareStyle}>
            <span style={squareStyle2}>
              <span className="project-role-wrapper installation-company-project-role-wrapper" style={style}>
                {link}
              </span>
            </span>
          </span>
        </span>
      </Tooltip>
    );
  };

  render() {
    const company = this.props.role.company;

    return (
      <Dropdown
        target={this.renderTarget}
        placement="bottom-end"
        isOpen={this.state.dropdownOpen}
        onClose={this.handleClose}>
        <div className="project-membership-details">
          <div className="project-membership-details-head">
            <div>
              <span className="avatar-wrapper avatar-wrapper-medium" title={company.display_name}>
                <img alt={company.display_name} src={company.avatar.url} className="avatar avatar-medium" />
              </span>
            </div>
            <div>
              <a href={`/installation_companies/${company.id}`}>{company.display_name}</a>
            </div>
          </div>
          <div className="project-membership-section">
            {company.manager && (
              <div>
                Manager: <a href={`/users/${company.manager.id}`}>{company.manager.display_name}</a>
              </div>
            )}
            {company.coordinator && (
              <div>
                Koordinator: <a href={`/users/${company.coordinator.id}`}>{company.coordinator.display_name}</a>
              </div>
            )}
          </div>
          {company.upcoming_appointments.length > 0 && (
            <div className="project-membership-section">
              <span className="text-secondary">Nächste Termine:</span>
              {company.upcoming_appointments.map((appointment) => (
                <div key={appointment.id}>
                  {appointment.installation_name}:&nbsp;
                  <a href={`/installations/${appointment.installation_id}/appointments/${appointment.id}`}>
                    {appointment.start_datetime}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </Dropdown>
    );
  }
}

CompanyRole.propTypes = {
  role: PropTypes.object,
};

export default CompanyRole;
