/* eslint-disable no-multi-spaces */
const compiledLocales = {};
const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];
const secondsFactor = [1, 60, 60, 24, 7, 365.25 / 7 / 12, 12];

// Gibt den Beginn des Tages zurück. Wenn diff negativ ist,
// wird der Beginn des nächsten Tages zurückgegeben
const snapToFullDay = (date, diff) => {
  if (diff > 0) return new Date(date).setHours(0, 0, 0, 0);

  const result = new Date(date);
  result.setDate(result.getDate() + 1);
  result.setHours(0, 0, 0, 0);
  return result;
};

// Korrekturfunktionen, um ein Datum vor der Berechnung der darzustellenden
// Differenz anzupassen. Wird insbesondere dazu verwendet, gestern und morgen, übermorgen, etc.
// korrekt darzustellen, da hier der Beginn eines Tages berücksichtigt werden muss.
const correctors = [
  date => date,
  date => date,
  date => date,
  snapToFullDay,
  snapToFullDay,
  snapToFullDay,
  date => date,
];

function getDuration(amount, unit) {
  for (let i = 0; i < units.length; i += 1) {
    if (unit.startsWith(units[i])) {
      return amount * secondsFactor.slice(0, i + 1).reduce((a, x) => a * x, 1);
    }
  }

  return 0;
}

function getDurationInUnit(diff, unitIndex) {
  return (diff / secondsFactor.slice(0, unitIndex + 1).reduce((a, x) => a * x, 1));
}

function interpolate(str, diffAbs, time) {
  const strInterpolated = str;
  // strInterpolated = str.replace('%dayName', time.format('dddd'));

  for (let i = 0; i < units.length; i += 1) {
    if (strInterpolated.includes(`%${units[i]}s`)) {
      const diffAbsInUnit = getDurationInUnit(diffAbs, i);
      return strInterpolated.replace(`%${units[i]}s`, Math.floor(diffAbsInUnit));
    }
  }

  return str;
}

// Gibt den Index des zutreffenden Intervalls der Local-Konfiguration zurück
function getIntervalIndex(diff, cl) {
  const diffAbs = Math.abs(diff);

  for (let i = 0; i < cl.length; i += 1) {
    if (!cl[i + 1] || (diffAbs > cl[i][0] && diffAbs <= cl[i + 1][0])) {
      return i;
    }
  }

  return -1;
}

// Gibt den Index der kleinsten Einheit zurück, die bei übergebenem
// Diff (Sekunden) einen ganzzahligen Wert > 1 erreicht.
// Z.B: diff: 75s => 1 Minute => Index: 1
function getFullUnitIndex(diffAbs) {
  for (let i = units.length - 1; i >= 0; i -= 1) {
    if (getDurationInUnit(diffAbs, i) >= 1) {
      return i;
    }
  }

  return 0;
}

// Gibt den passenden TimeAgo-String zu einer Zeit und einer Locale
// zurück
function getString(time, locale) {
  const compiledLocale = compiledLocales[locale];

  const now = new Date();
  const diff = (time - now) / 1000;

  const fullUnitIndex = getFullUnitIndex(Math.abs(diff));

  // Korrigiere aktuelle Zeit anhand der Korrektoren für
  // die unterschiedliche Einheiten
  const nowCorrected = correctors[fullUnitIndex](now, diff);
  const correctedDiff = (time - nowCorrected) / 1000;

  const diffAbs = Math.abs(correctedDiff);
  const index = getIntervalIndex(correctedDiff, compiledLocale);

  if (correctedDiff <= 0) {
    return interpolate(compiledLocale[index][1], diffAbs, time);
  }

  return interpolate(compiledLocale[index][2], diffAbs, time);
}

/* global document */
function render(domNode) {
  const time = new Date(domNode.getAttribute('data-datetime'));
  const locale = domNode.getAttribute('data-locale');
  domNode.innerHTML = getString(time, locale);
  domNode.innerHTML = `
    <span class="d-print-none">${getString(time, locale)}</span>
    <span class="d-none d-print-inline">${time.toLocaleString()}</span>
  `;
}

function renderAll() {
  document.querySelectorAll('time.timeago').forEach((domNode) => {
    render(domNode);
  });
}

function renderAllInNode(node) {
  node.querySelectorAll('time.timeago').forEach((domNode) => {
    render(domNode);
  });
}

function start() {
  // Initial alle Dom-Nodes rendern
  renderAll();

  // Dann globalen Timer (Inverval aufsetzen)
  if (!window.timeagoTimer) {
    window.timeagoTimer = setInterval(renderAll, 20000);
  }
}

function stop() {
  if (window.timeagoTimer) {
    clearInterval(window.timeagoTimer);
    delete window.timeagoTimer;
  }
}

function registerLocale(locale) {
  const compiled = locale.steps.map(entry => ([
    getDuration(...entry[0]), entry[1], entry[2],
  ]));

  compiledLocales[locale.name] = compiled;
}

export {
  registerLocale,
  render,
  renderAll,
  renderAllInNode,
  start,
  stop,
  getString,
};
