import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import { flashNotice, flashError } from '../helpers/flash_helper';

async function updateOrder(order) {
  const response = await fetch(`${window.location.href}/sort`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    },
    body: JSON.stringify({ order }),
  });

  if (response.ok) {
    flashNotice('Reihenfolge wurde gespeichert.');
  } else {
    flashError('Es gab einen Fehler beim Speichern der Reihenfolge.');
  }
}

class SortController extends Controller {
  static targets = ['list'];

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
      handle: '.handle',
      onEnd: this.end.bind(this),
      animation: 200,
      forceFallback: true,
      fallbackClass: 'sortable-fallback',
      filter: '.non-sortable',
      preventOnFilter: false,
    });
  }

  end() {
    const order = Array.from(this.listTarget.children)
      .filter(child => !child.classList.contains('non-sortable'))
      .map((child, index) => {
        return { id: child.dataset.id, position: index + 1 };
      });

    updateOrder(order);
  }
}

export default SortController;
